import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { testWebP } from '../../imageUtils.js'
import LazyLoad from 'react-lazyload';
import Skeleton from 'react-loading-skeleton';

function SpreadingsListItem() {
   const [webPSupported, setWebPSupported] = useState(true);
   const products = [{
      seoTitle: '"Песто" - с зеленым базиликом',
      title: 'Песто',
      text: `Масло подсолнечное рафинированное дезодорированное, базилик зеленый свежий, ядра ореха кешью, масло оливковое нерафинированное высшего качества (Extra virgin olive oil), ядра кедровых орехов, соль морская пищевая, чеснок сушенный, регулятор кислотности - лимонная кислота.`,
      image: '../img/spreadings/pesto.jpg',
      alt: 'Песто с зелёным базиликом',
      cpf: {
         kcal: '480',
         kj: '1960',
         proteins: '2',
         fats: '51',
         сarbohydrates: '2',
         wt: '90',
      }
   },
   {
      seoTitle: '"Южная" - с овощами-гриль',
      title: 'Южная',
      text: `Вода питьевая, бобы соевые, масло подсолнечное рафинированное дезодорированное, томат сушёный, соль морская, лук сушёный, чеснок сушёный, эмульгатор (соевый лецитин), паприка копчёная, перец чёрный молотый, сахар, загуститель (гуаровая камедь), уплотнитель (хлорид магния);`,
      image: '../img/spreadings/south.jpg',
      alt: 'Южная с овощами-гриль',
      cpf: {
         kcal: '253',
         kj: '1058',
         proteins: '1,7',
         fats: '22,5',
         сarbohydrates: '5,4',
         wt: '100',
      }
   },
   {
      seoTitle: '"Кавказская" - с баклажаном-гриль',
      title: 'Кавказская',
      text: `Вода питьевая, бобы соевые, масло подсолнечное рафинированное дезодорированное, баклажан сушёный, соль морская, чеснок сушёный, эмульгатор (соевый лецитин), паприка копчёная, перец чёрный молотый, загуститель (гуаровая камедь), уплотнитель (хлорид магния);`,
      image: '../img/spreadings/Caucasian.jpg',
      alt: 'Кавказская с баклажаном-гриль',
      cpf: {
         kcal: '174',
         kj: '728',
         proteins: '1',
         fats: '15,6',
         сarbohydrates: '5,9',
         wt: '100',
      }
   },
   {
      seoTitle: '"Уральская" - с белыми грибами',
      title: 'Уральская',
      text: `Вода питьевая, бобы соевые, масло подсолнечное рафинированное дезодорированное, соль морская, грибы белые сушёные, лук сушёный, эмульгатор (лецитин соевый), перец чёрный молотый, загуститель (гуаровая камедь), уплотнитель (хлорид магния).`,
      image: '../img/spreadings/Uralskaya.jpg',
      alt: 'Уральская с белыми грибами',
      cpf: {
         kcal: '304',
         kj: '1272',
         proteins: '2',
         fats: '29,6',
         сarbohydrates: '5,5',
         wt: '100',
      }
   },
   {
      seoTitle: '"Дальневосточная" - с морским вкусом',
      title: 'Дальневосточная',
      text: `Вода питьевая, бобы соевые, масло подсолнечное рафинированное дезодорированное, соль морская, водоросли сушёные вакаме, соус соевый (вода питьевая, бобы соевые, соль пищевая, пшеница), сахар, имбирь молотый, эмульгатор (лецитин соевый), загуститель (гуаровая камедь), уплотнитель (хлорид магния).`,
      image: '../img/spreadings/Far Eastern.jpg',
      alt: 'Дальневосточная с морским вкусом',
      cpf: {
         kcal: '224',
         kj: '1020',
         proteins: '1,5',
         fats: '23,5',
         сarbohydrates: '5',
         wt: '100',
      }
   },
   {
      seoTitle: '"Национальная" - с пряной свёклой',
      title: 'Национальная',
      text: `Вода питьевая, бобы соевые, масло подсолнечное рафинированное дезодорированное, свекла сушеная, пюре из свеклы, соль морская, чеснок сушеный, сахар, эмульгатор (лецитин соевый), кориандр, зира, регулятор кислотности (лимонная кислота), загуститель (гуаровая камедь), уплотнитель (хлорид магния).`,
      image: '../img/spreadings/national.jpg',
      alt: 'Национальная с пряной свёклой',
      cpf: {
         kcal: '178',
         kj: '745',
         proteins: '1,6',
         fats: '16',
         сarbohydrates: '6',
         wt: '100',
      }
   },
   {
      seoTitle: '"Кубанская" - с вяленым томатом и базиликом',
      title: 'Кубанская',
      text: `Вода питьевая, бобы соевые, масло подсолнечное рафинированное дезодорированное, томатная паста, соль морская, томатные хлопья, паприка, сахар, базилик сушеный, эмульгатор (лецитин соевый), чеснок сушеный, лук сушеный, перец черный молотый, загуститель (гуаровая камедь), уплотнитель (хлорид магния)`,
      image: '../img/spreadings/Kuban.jpg',
      alt: 'Кубанская с вяленым томатом и базиликом',
      cpf: {
         kcal: '160',
         kj: '669',
         proteins: '1,8',
         fats: '13,6',
         сarbohydrates: '6,3',
         wt: '100',
      }
   },
   {
      seoTitle: '"Столичная" - с маринованными огоурчиками',
      title: 'Столичная',
      text: `Вода питьевая, бобы соевые, масло подсолнечное рафинированное дезодорированное, огурцы маринованные (огурцы, вода, уксус, кислота лимонная, соль),  соль морская, сахар, эмульгатор (лецитин соевый), чеснок сушёный, укроп сушёный, перец чёрный молотый, загуститель (гуаровая камедь), уплотнитель (хлорид магния).`,
      image: '../img/spreadings/Metropolitan.jpg',
      alt: 'Столичная с маринованными огурчиками',
      cpf: {
         kcal: '185',
         kj: '774',
         proteins: '1,5',
         fats: '17',
         сarbohydrates: '5,5',
         wt: '100',
      }
   },
   {
      seoTitle: '"Сибирская" со вкусом хрена',
      title: 'Сибирская',
      text: `Вода питьевая, бобы соевые, масло подсолнечное рафинированное дезодорированное, хрен сушёный молотый, соль морская, эмульгатор (лецитин соевый), чеснок сушёный, горчица сушёная, перец чёрный молотый, загуститель (гуаровая камедь), уплотнитель (хлорид магния).`,
      image: '../img/spreadings/Siberian.jpg',
      alt: 'Сибирская со вкусом хрена',
      cpf: {
         kcal: '214',
         kj: '895',
         proteins: '1,8',
         fats: '19,6',
         сarbohydrates: '6',
         wt: '100',
      }
   },
   ]

   const [isLoaded, setIsLoaded] = useState(Array(products.length).fill(false));
   const [keys, setKeys] = useState(products.map(() => uuidv4()));

   const handleImageLoad = (index) => {
      const newImageStates = [...isLoaded];
      newImageStates[index] = true;
      setIsLoaded(newImageStates);
   };

   useEffect(() => {
      // Обновить ключи, если количество элементов изменилось
      if (products.length !== keys.length) {
         setKeys(products.map(() => uuidv4()));
      }
   }, [products, keys]);


   return (
      products.map((item, index) => (
         <div className="product_list__item row" key={keys[index]}>
            <div className="col-md-4">
               <LazyLoad className="image" height={0} offset={0}>
                  {isLoaded[index] ? null : <Skeleton height={'100%'} />}
                  <img src={testWebP(item.image, webPSupported, setWebPSupported)} alt={item.title}
                     title={item.title} onLoad={() => handleImageLoad(index)} />
               </LazyLoad>
            </div>
            <div className="col-xl-5 col-md-8">
               <div className="content">
                  <h2 className="content__title">{item.title}</h2>
                  <div className="content__text">
                     <p>
                        {item.text}
                     </p>
                  </div>
                  <button href="#" className="content__btn">Показать состав</button>
               </div>
            </div>
            <div className="col-xl-3">
               <div className="meta_wrap">
                  <button href="#" className="close">
                     <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="20" fill="#F3EAD9" />
                        <rect x="11" y="11.92" width="24" height="4" transform="rotate(30 11 11.92)"
                           fill="#23482C" />
                        <rect x="9" y="23.92" width="24" height="4" transform="rotate(-30 9 23.92)" fill="#23482C" />
                     </svg>
                  </button>
                  <div className="title">{item.title}</div>
                  <div className="text">
                     <p>
                        {item.text}
                     </p>
                  </div>
                  <div className="meta">
                     <div className="meta__item">
                        <div className="name">
                           Энергетическая <br /> ценность
                        </div>
                        <div className="value">
                           <span>{item.cpf.kcal} ккал</span>
                           <span>{item.cpf.kj} кДж</span>
                        </div>
                     </div>
                     <div className="meta__item">
                        <div className="name">Белки</div>
                        <div className="value">{item.cpf.proteins} г</div>
                     </div>
                     <div className="meta__item">
                        <div className="name">Жиры</div>
                        <div className="value">{item.cpf.fats} г</div>
                     </div>
                     <div className="meta__item">
                        <div className="name">Углеводы</div>
                        <div className="value">{item.cpf.сarbohydrates} г</div>
                     </div>
                     <div className="meta__last">
                        <div className="value">{item.cpf.wt} г</div>
                        <div className="name">
                           Продукт готов
                           к&nbsp;употреблению
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      ))
   );
}

export default SpreadingsListItem;